const firebaseConfig = {
  projectId: "the-marlborough-wavey",
  appId: "1:405033632704:web:39eae166f4014bebc03e25",
  databaseURL:
    "https://the-marlborough-wavey-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-marlborough-wavey.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyBXFi4qA6LR0Ql9FCiEshmXuKQLrsUpJgE",
  authDomain: "the-marlborough-wavey.firebaseapp.com",
  messagingSenderId: "405033632704",
  measurementId: "G-6PKYW02MRT",
};

export default firebaseConfig;
